import { useState } from 'react';
import styled from 'styled-components';

export const TextInput = ({
  placeholder,
  onChange,
  onBlur,
  onClick,
  onKeyDown,
  value,
  validationMessage,
  name,
  id,
  className,
  autoComplete = 'on',
  labelVisible = false,
  valid = true,
  disabled = false,
  type = 'text'
}: {
  placeholder: string;
  value: string;
  valid?: boolean;
  disabled?: boolean;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
  validationMessage?: string;
  name?: string;
  id?: string;
  autoComplete?: 'on' | 'off';
  type?: React.HTMLInputTypeAttribute;
  labelVisible?: boolean;
  className?: string;
}) => {
  const [showLabel, setShowLabel] = useState(false);

  const handleInputFocus = () => {
    setShowLabel(true);
  };

  const Component = valid ? Input : InvalidInput;

  return (
    <TextInputWrapper className={className}>
      <Label className={showLabel || labelVisible ? 'showLabel' : ''}>{placeholder}</Label>
      <Component
        value={value}
        placeholder={placeholder}
        onChange={e => onChange && onChange(e.target.value)}
        onBlur={() => onBlur && onBlur()}
        onClick={(e: React.MouseEvent<HTMLElement>) => onClick && onClick(e)}
        onFocus={handleInputFocus}
        onKeyDown={(e: React.KeyboardEvent<HTMLElement>) => onKeyDown && onKeyDown(e)}
        disabled={disabled}
        name={name}
        id={id}
        autoComplete={autoComplete}
        type={type}
      />
      {!valid && <ErrorMessage>{validationMessage}</ErrorMessage>}
    </TextInputWrapper>
  );
};

const TextInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  height: 38px;
  font-size: 16px;
  padding: 8px;
  box-sizing: border-box;
  margin: 0;
  border-radius: 8px;
  border: 2px solid #14120a1a;
  width: 100%;
  cursor: pointer;
  margin-top: 4px;
  margin-bottom: 12px;

  &:focus-visible {
    border: 2px solid #1b3732;
    outline: none !important;
  }

  &:disabled {
    background: #f2f2f2;
    cursor: not-allowed;
  }

  &:hover:not(:focus) {
    background: #14120a08;
  }
`;

const InvalidInput = styled(Input)`
  border: 2px solid #9a3300;
  background-color: rgba(154, 51, 0, 0.1);
  margin-bottom: 4px;

  &:focus-visible {
    border: 2px solid 1b3732;
    background: #ffffff;
  }
`;

const Label = styled.label`
  font-size: 14px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s;

  &.showLabel {
    visibility: visible;
    opacity: 1;
  }
`;

const ErrorMessage = styled.div`
  font-size: 13px;
  color: #9a3300;
  margin-bottom: 12px;
`;
