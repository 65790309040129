import { createGlobalStyle } from 'styled-components';

import AgipoRegular from '../../../assets/fonts/agipo_regular.otf';

import AgipoItalicBold from '../../../assets/fonts/agipo_bold_italic.otf';
import AgipoItalic from '../../../assets/fonts/agipo_regular_italic.otf';

export const FontFace = createGlobalStyle`
    @font-face {
      font-family: 'Agipo';
      font-style: normal;
      font-weight: 400;

      src: url(${AgipoRegular}) format('opentype');
    }
    
    @font-face {
        font-family: 'Agipo';
        font-style: italic;
        font-weight: 700;
    
        src: url(${AgipoItalicBold}) format('opentype');
    }

    @font-face {
        font-family: 'Agipo';
        font-style: italic;
        font-weight: 400;
    
        src: url(${AgipoItalic}) format('opentype');
    }
`;
