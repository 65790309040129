export type CreateOrderDto = {
  customer: CustomerDto;
  product: ProductDto;
  address: AddressDto;
  deliveryDate: string;
};

export type GetPaymentStatusDto = {
  sessionId: string | null;
};

export type CustomerDto = {
  firstName: string;
  lastName: string;
  mobile: string;
  email: string;
};

export type ProductDto = {
  id: number;
  price: number;
};

export type AddressDto = {
  regionId: number;
  zipCode: string;
  postalName: string;
  streetName: string;
  streetNumber: number;
  entrance: string;
  floorType?: string;
  floorNumber?: number;
  flatNumber?: number;
  deliveryPointId: number;
};

export type CreateOrderResponse = {
  sessionId: string;
  status: string;
  redirectUrl: string;
  termUrl: string;
};

export type BillingInformationDto = {
  paymentMethodType: string;
};

export type DeliveriesDto = {
  freightPrice: number;
  products: ProductsDto[];
  recipient: RecipientDto;
};

export type ProductsDto = {
  id: number;
  price: number;
  quantity: number;
  title: string;
};

export type RecipientDto = {
  city: string;
  co: string;
  countryCode: string;
  deliveryPointId: number;
  deliverySms: boolean;
  entrance: string;
  firstName: string;
  flatNo: number;
  floorNo: number;
  floorType: string;
  lastName: string;
  mobile: string;
  regionId: number;
  streetName: string;
  streetNo: number;
  zip: string;
};

export type PaymentDto = {
  discountedAmount: number;
  grossAmount: number;
  vatAmount: number;
};

export type ProductImageDto = {
  alt: string;
  src: string;
  variant_ids: number[];
};

export type GetOrderResponse = {
  deliveries: DeliveriesDto[];
  deliveryDate: string;
  payment: PaymentDto;
  productImage: ProductImageDto;
};

export enum PaymentStatus {
  CANCEL = 'CANCEL',
  SALE = 'SALE'
}

export type GetPaymentStatusResponse = {
  paymentStatus: PaymentStatus;
  productId: string;
};
