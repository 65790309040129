export type Street = {
  countryCode: string;
  city: string;
  streetName: string;
  streetIds: number[];
};

export type GetStreetResponse = {
  streets: Street[];
  totalResults: number;
};

export type StreetNumber = {
  streetNo: number;
  addressId: number;
  /**
   * Present if a building has several entrances, where the value is the entrance name
   */
  entrance?: string;
  houseType: string;
  deliveryPointId: number;
  postalCode: string;
  /**
   * Some streets have several entrances on the same street number.
   * When duplicateNumberAndAddress is true, the houseType should be used to separate them
   */
  duplicateNumberAndEntrance: boolean;
  latitude: number;
  longitude: number;
  /**
   * True if households exists on houseType block
   */
  showHouseholds: boolean;
};

export type GetStreetNumbersResponse = {
  streetNumbers: StreetNumber[];
};

export type Floor = {
  /**
   * The floor type represented by a single letter
   */
  floorType: string;
  /**
   * The full word of the floor type (in Norwegian)
   */
  floorTypeName: string;
  floorNo: number;
};

export type GetFloorsResponse = Floor[];

export type Household = {
  flatNo: number;
  deliveryPointId: number;
  flatNoAlias: string;
};

export type GetHouseholdsResponse = Household[];

export type AddressDetails = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  street: Street | null;
  streetNo: StreetNumber | null;
  floor: Floor | null;
  apartment: Household | null;
};

export const completedAddressDetailsGuard = (
  address: AddressDetails
): address is
  | (AddressDetails & { street: Street; streetNo: StreetNumber })
  | (AddressDetails & { street: Street; streetNo: StreetNumber; floor: Floor; apartment: Household }) => {
  if (!address.street || !address.streetNo) {
    return false;
  }

  if (address.streetNo.showHouseholds && (!address.floor || !address.apartment)) {
    return false;
  }

  return true;
};
