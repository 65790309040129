import axios, { AxiosError } from 'axios';
import * as Sentry from '@sentry/browser';

import { MorgenleveringProduct } from '../types/morgenlevering-product.type';
import { envVariables } from '../../../env-variables';
import { DeliveryDate, GetDeliveryDatesRequestDto } from '../types/delivery-date.type';
import {
  GetFloorsResponse,
  GetHouseholdsResponse,
  GetStreetNumbersResponse,
  GetStreetResponse,
  Street,
  StreetNumber
} from '../types/addresses.types';
import {
  CreateOrderDto,
  CreateOrderResponse,
  GetOrderResponse,
  GetPaymentStatusDto,
  GetPaymentStatusResponse
} from '../types/order.types';
import { isAxiosError } from '../../../types/axios-error';

export const getProduct = async (productId: string): Promise<MorgenleveringProduct> => {
  try {
    const { data } = await axios.get<{ product: MorgenleveringProduct }>(
      `${envVariables.apiUrl}/products/${productId}/vendors/morgenlevering`
    );
    return data.product;
  } catch (e) {
    throw new Error('FAILED_TO_FETCH_PRODUCT');
  }
};

export const getDeliveryDates = async (address: GetDeliveryDatesRequestDto, productId: number): Promise<DeliveryDate[]> => {
  try {
    const { data } = await axios.post<DeliveryDate[]>(
      `${envVariables.apiUrl}/morgenlevering/delivery-dates/${productId}`,
      address
    );
    return data;
  } catch (e) {
    // LOG if unknown error or unexpected status occurs. We want to know.
    if (!isAxiosError(e)) {
      Sentry.captureException(e, {
        contexts: {
          context: {
            method: 'getDeliveryDates',
            info: 'Unknown error type catched from GET: /delivery-dates',
            productId,
            address,
            contextType: 'CUSTOM'
          }
        }
      });
    } else if (e.response?.status !== 409) {
      Sentry.captureException(e, {
        contexts: {
          context: {
            view: 'getDeliveryDates',
            info: 'Unknown status code returned from GET: /delivery-dates',
            productId,
            address,
            contextType: 'CUSTOM'
          }
        }
      });
    }

    throw new Error('FAILED_TO_FETCH_DELIVERY_DATES');
  }
};

export const getStreetNames = async (query: string): Promise<Street[]> => {
  try {
    const { data } = await axios.get<GetStreetResponse>(`${envVariables.apiUrl}/address/street/${query}`);
    return data.streets;
  } catch (e) {
    throw new Error('FAILED_TO_FETCH_ADDRESS');
  }
};

export const getStreetNumbers = async (streetIds: number[], query: string): Promise<StreetNumber[]> => {
  try {
    const { data } = await axios.get<GetStreetNumbersResponse>(
      `${envVariables.apiUrl}/v2/address/street-numbers?streetIds=${streetIds.join(',')}${
        query ? `&streetNumber=${query}` : ''
      }`
    );
    return data.streetNumbers;
  } catch (e) {
    throw new Error('FAILED_TO_FETCH_ADDRESS');
  }
};

export const getFloors = async (deliveryPointId: number): Promise<GetFloorsResponse> => {
  try {
    const { data } = await axios.get<GetFloorsResponse>(`${envVariables.apiUrl}/address/floors/${deliveryPointId}`);
    return data;
  } catch (e) {
    throw new Error('FAILED_TO_FETCH_ADDRESS');
  }
};

export const getApartments = async ({
  deliveryPointId,
  floorType,
  floorNo
}: {
  deliveryPointId: number;
  floorNo: number;
  floorType: string;
}): Promise<GetHouseholdsResponse> => {
  try {
    const { data } = await axios.get<GetHouseholdsResponse>(
      `${envVariables.apiUrl}/address/households/${deliveryPointId}/${floorType}/${floorNo}`
    );
    return data;
  } catch (e) {
    throw new Error('FAILED_TO_FETCH_ADDRESS');
  }
};

export const createOrder = async (orderDto: CreateOrderDto): Promise<CreateOrderResponse> => {
  try {
    const { data } = await axios.post<CreateOrderResponse>(`${envVariables.apiUrl}/morgenlevering/order/create`, orderDto);
    return data;
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.status === 404) throw new Error('PRODUCT_NOT_AVAILABLE');
    else throw new Error('FAILED_TO_CREATE_ORDER');
  }
};

export const getPaymentStatus = async (paymentStatusDto: GetPaymentStatusDto): Promise<GetPaymentStatusResponse> => {
  try {
    const { data } = await axios.post<GetPaymentStatusResponse>(
      `${envVariables.apiUrl}/morgenlevering/order/finalize`,
      paymentStatusDto
    );
    return data;
  } catch (e) {
    throw new Error('FAILED_TO_POST_SESSION_ID');
  }
};

export const getOrder = async (sessionId: string): Promise<GetOrderResponse> => {
  try {
    const { data } = await axios.get<GetOrderResponse>(`${envVariables.apiUrl}/morgenlevering/order/session/${sessionId}`);
    return data;
  } catch (e) {
    throw new Error('FAILED_TO_FETCH_ORDER');
  }
};
