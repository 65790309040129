import React from 'react';
import styled from 'styled-components';

export const Annonse = ({ className }: { className?: string }) => {
  return <StyledAnnonse className={className}>annonse</StyledAnnonse>;
};

const StyledAnnonse = styled.div`
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  background: linear-gradient(0deg, #5e6b66, #5e6b66), rgba(27, 55, 50, 0.6);
  padding: 4px;
  height: 25px;
`;
