/* eslint-disable complexity */
export const getMonthName = (month: number | undefined) => {
  return month === 0
    ? 'januar'
    : month === 1
    ? 'februar'
    : month === 2
    ? 'mars'
    : month === 3
    ? 'april'
    : month === 4
    ? 'mai'
    : month === 5
    ? 'juni'
    : month === 6
    ? 'juli'
    : month === 7
    ? 'august'
    : month === 8
    ? 'september'
    : month === 9
    ? 'oktober'
    : month === 10
    ? 'november'
    : month === 11
    ? 'desember'
    : '';
};
