import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

import { Price } from './common/price';
import { Title } from './common/title';
import { getProduct } from './client/morgenevering-client';
import { MorgenleveringProduct } from './types/morgenlevering-product.type';
import { ErrorView } from './error-view';
import { CollapsableTab } from '../../components/collapsable-tab/collapsable-tab';
import { HeaderDescription } from './common/header-description';
import { HeaderDeliveryInformation } from './header-delivery-information';
import { MorgenleveringLogo } from './common/morgenlevering-logo';
import vipps_Logo from '../../assets/betal-med-vipps-oransj.png';
import { CloseButton } from './common/close-button';
import { EventCategory, EventLabel, EventName, trackEvent, trackPage } from '../../analytics/analytics';
import { PageParams } from '../../types/page-params';
import { WithMorgenleveringTheme } from './theme/with-morgenlevering-theme';
import { Card } from './layout/card';
import { convertToUrlSafe } from './converters/url-safe-convereter';

export const Morgenlevering = () => {
  const { productId } = useParams<PageParams>();

  const [product, setProduct] = useState<MorgenleveringProduct | null>(null);
  const [error, setError] = useState(false);
  const [openedTab, setOpenedTab] = useState<string>('');

  const onTabClick = (title: string) => {
    setOpenedTab(title);
  };

  useEffect(() => {
    trackPage();
  }, []);

  useEffect(() => {
    if (productId) {
      getProduct(productId)
        .then(product => setProduct(product))
        .catch(() => setError(true));
    }
  }, [productId]);

  if (error) {
    return <ErrorView />;
  }

  return (
    <WithMorgenleveringTheme>
      <Card>
        <div>
          <MorgenleveringLogo />
          <CloseButton />
          <ProductContainer>
            {product && product.images.length > 0 ? (
              <ProductImage src={product?.images[0].src} alt={`Product`} />
            ) : (
              <ProductImageSkeletonContainer>
                <Skeleton width={310} height={310} />
              </ProductImageSkeletonContainer>
            )}
            <VippsLogo src={vipps_Logo} alt='Vipps logo' />

            <ProductDetails>
              <Title title={product?.title} />

              <HeaderDescription />

              <HeaderDeliveryInformation />

              <Price price={product?.price || ''} />

              <CollapsableTab title='Pakkens innhold' onTabClick={onTabClick} openedTab={openedTab}>
                <span dangerouslySetInnerHTML={{ __html: product?.details.description || '' }} />
              </CollapsableTab>

              <CollapsableTab title='Allergener' onTabClick={onTabClick} openedTab={openedTab}>
                <span dangerouslySetInnerHTML={{ __html: product?.details.allergens || '' }} />
              </CollapsableTab>
              <ButtonContainer>
                <Link
                  to={{
                    pathname: `/morgenlevering/${productId}/form`,
                    state: {
                      product: product
                    }
                  }}
                  onClick={() =>
                    trackEvent({
                      eventName: EventName.ORDER_BUTTON_CLICK,
                      label: EventLabel.ORDER,
                      category: EventCategory.CLICK
                    })
                  }
                >
                  <OrderButton>Bestill</OrderButton>
                </Link>
                <OutlineLink
                  href={`https://morgenlevering.no/products?utm_source=ministore&utm_campaign=${convertToUrlSafe(
                    product?.title
                  )}`}
                  target='_blank'
                  onClick={() =>
                    trackEvent({
                      eventName: EventName.ML_LINK_CLICK,
                      category: EventCategory.CLICK,
                      label: EventLabel.ML_LINK
                    })
                  }
                >
                  Se mer på Morgenlevering
                </OutlineLink>
              </ButtonContainer>
            </ProductDetails>
          </ProductContainer>
        </div>
      </Card>
    </WithMorgenleveringTheme>
  );
};

const VippsLogo = styled.img`
  height: 29px;
  width: 60px;
  margin-left: auto;
`;

const OrderButton = styled.div`
  color: #fffdf5;
  font-size: 16px;
  font-weight: 700;
  background: #1b3732;
  border-radius: 8px;
  padding: 12px 32px;
  float: right;
  cursor: pointer;
  min-width: 247px;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;

  &:hover {
    opacity: 0.85 !important;
  }
`;

const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProductDetails = styled.div`
  width: 100%;
`;

const ProductImage = styled.img`
  margin-top: -50px;
  height: 310px;
  width: auto;
`;

const ProductImageSkeletonContainer = styled.div`
  margin-top: -50px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`;

const OutlineLink = styled.a`
  margin-top: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 247px;
  min-height: 48px;
  border-radius: 8px;
  border: 2px solid #1b3732;
  color: #1b3732;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    opacity: 0.85 !important;
  }
`;
