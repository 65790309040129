import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import ProductPage from './ProductPage';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import SuccessPage from './SuccessPage';
import { envVariables } from './env-variables';
import { FormViewWithRouter } from './vendors/morgenlevering/form-view/form-view';
import { Morgenlevering } from './vendors/morgenlevering/morgenlevering';
import { VendorMorgenleveringSuccessPage } from './vendors/morgenlevering/success-page/success-page';
import TagManager from 'react-gtm-module';

import './index.css';

if (envVariables.sentryDSN) {
  Sentry.init({
    dsn: envVariables.sentryDSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: envVariables.sentryTraceRate,
    environment: envVariables.sentryEnv
  });
}

const tagManagerArgs = {
  gtmId: envVariables.gtmId,
  auth: envVariables.gtmAuth,
  preview: envVariables.gtmPreview
};

TagManager.initialize(tagManagerArgs);
console.log('>>tagManagerArgs:', tagManagerArgs);
ReactDOM.render(
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
    <Router>
      <Switch>
        <Route path='/status/:vendorId/:productId/:orderId' children={<SuccessPage />} />
        <Route path='/morgenlevering/:productId/form' children={<FormViewWithRouter />} />
        <Route path='/morgenlevering/:productId/' children={<Morgenlevering />} />
        <Route path='/morgenlevering' children={<VendorMorgenleveringSuccessPage />} />
        <Route path='/:vendorId/:productId/:variantId' children={<ProductPage />} />
        <Route path='/:vendorId/:productId/' children={<ProductPage />} />
      </Switch>
    </Router>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
);
