export type DeliveryDate = {
  date: string;
  availability: DeliveryCapacityStatus;
};

export enum DeliveryCapacityStatus {
  NO_DELIVERY = 'NO_DELIVERY',
  AVAILABLE = 'AVAILABLE',
  FULLY_BOOKED = 'FULLY_BOOKED'
}

export type GetDeliveryDatesRequestDto = {
  countryCode: string;
  postalName: string;
  zipCode: string;
  streetAddress: string;
  recipient: string;
};
