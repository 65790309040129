import React from 'react';

import chevron from '../../assets/chevron.svg';

import './index.css';

export const CollapsableTab = ({
  title,
  onTabClick,
  openedTab,
  children
}: {
  title: string;
  onTabClick: (title: string) => void;
  openedTab: string;
  children: React.ReactNode;
}) => {
  const isTabOpen = openedTab === title;

  const handleOnTabClick = () => (openedTab === title ? onTabClick('') : onTabClick(title));

  return (
    <div className='collapsable-tab'>
      <button className='head' onClick={handleOnTabClick}>
        {title}{' '}
        <span>
          <img className={`chevron${isTabOpen ? ' open' : ''}`} src={chevron} alt='toggle tab' />
        </span>
      </button>
      {isTabOpen && <div className='content'>{children}</div>}
    </div>
  );
};
