import React from 'react';
import styled from 'styled-components';

export const HeaderDescription = ({ className }: { className?: string }) => {
  return <StyledHeaderDescription className={className}>Kan leveres allerede i morgen tidlig</StyledHeaderDescription>;
};

const StyledHeaderDescription = styled.div`
  font-size: 15px;
  font-style: italic;
`;
