import React, { useRef } from 'react';
import styled from 'styled-components';
import { PulseLoader } from 'react-spinners';

import { DeliveryCapacityStatus, DeliveryDate } from '../types/delivery-date.type';
import { useOnClickOutside } from '../../../hooks/use-click-outside';

const oneDaySpace = 14.2857142857;

export const DateSelector = ({
  className,
  onChange,
  deliveryDates,
  value,
  onClickOutside,
  isLoading
}: {
  className?: string;
  onChange: (date: string) => void;
  deliveryDates: DeliveryDate[];
  value: string;
  onClickOutside: () => void;
  isLoading: boolean;
}) => {
  const datesSelectorRef = useRef(null);

  useOnClickOutside(datesSelectorRef, () => {
    onClickOutside();
  });

  const calculateOffset = () => new Date(deliveryDates[0].date).getDay();

  const isDateOffset = (date: string) => {
    return new Date(date).toDateString() === new Date(deliveryDates[0].date).toDateString();
  };

  const isSelected = (date: string) => {
    return date === value;
  };

  const days = ['MAN', 'TIR', 'ONS', 'TOR', 'FRE', 'LØR', 'SØN'];

  const deliveryCapacityToClass = (availability: DeliveryCapacityStatus): string => {
    switch (availability) {
      case DeliveryCapacityStatus.AVAILABLE:
        return '';
      case DeliveryCapacityStatus.FULLY_BOOKED:
        return 'calendar-day-fully-booked';
      case DeliveryCapacityStatus.NO_DELIVERY:
        return 'calendar-day-unavailable';
      default:
        return '';
    }
  };

  return (
    <SelectorContainer ref={datesSelectorRef}>
      <StyledDateSelector className='calendar'>
        <div className='days-of-week'>
          {days.map(day => (
            <div className='day' key={day}>
              {day}
            </div>
          ))}
        </div>
        <div className='available-days'>
          {isLoading && (
            <LoaderContainer>
              <PulseLoader color='black' size={16} />
            </LoaderContainer>
          )}
          {!isLoading &&
            deliveryDates.map(({ date, availability }) => (
              <div className={`calendar-day ${isDateOffset(date) && `calendar-day-offset-${calculateOffset()}`}`} key={date}>
                <button
                  onClick={() => onChange(date)}
                  className={(isSelected(date) && 'calendar-day-active') || deliveryCapacityToClass(availability)}
                  disabled={[DeliveryCapacityStatus.NO_DELIVERY, DeliveryCapacityStatus.FULLY_BOOKED].includes(availability)}
                >
                  {new Date(date).getDate()}
                </button>
              </div>
            ))}
        </div>
        <div className='legend'>
          <span className='legend-item available'>Ledig</span>
          <span className='legend-item unavailable'>Ikke levering</span>
          <span className='legend-item fully-booked'>Fullbooket</span>
        </div>
      </StyledDateSelector>
    </SelectorContainer>
  );
};

const StyledDateSelector = styled.div`
  min-width: 275px;
  max-width: 320px;
  margin: 0 auto;

  .days-of-week {
    display: flex;
    flex-wrap: wrap;
    font-weight: bold;
    font-size: 0.75rem;

    .day {
      color: #1b3732;
      margin: 0.25rem 0 1rem;
      flex-grow: 0;
      flex-shrink: 0;
      text-align: center;
      width: ${oneDaySpace}%;
    }
  }

  .available-days {
    display: flex;
    flex-wrap: wrap;

    .calendar-day {
      flex-grow: 0;
      flex-shrink: 0;
      text-align: center;
      width: ${oneDaySpace}%;

      &.calendar-day-offset-1 {
        margin-left: 0px;
      }

      &.calendar-day-offset-2 {
        margin-left: ${oneDaySpace}%;
      }

      &.calendar-day-offset-3 {
        margin-left: ${oneDaySpace * 2}%;
      }

      &.calendar-day-offset-4 {
        margin-left: ${oneDaySpace * 3}%;
      }

      &.calendar-day-offset-5 {
        margin-left: ${oneDaySpace * 4}%;
      }

      &.calendar-day-offset-6 {
        margin-left: ${oneDaySpace * 5}%;
      }

      &.calendar-day-offset-0 {
        margin-left: ${oneDaySpace * 6}%;
      }

      button {
        cursor: pointer;
        font-size: 0.8125rem;
        background-color: #cfedcf;
        color: #1b3732;
        text-decoration: underline;
        border: none;
        border-radius: 50%;
        height: 2rem;
        width: 2rem;
        padding: 0;
        margin-bottom: 0.5rem;

        &.calendar-day-active {
          background: #1b3732;
          color: #fff;
        }

        &.calendar-day-unavailable {
          pointer-events: none;
          background: rgba(154, 51, 0, 0.1);
          color: #9a3300;
          text-decoration: none;
        }

        &.calendar-day-fully-booked {
          pointer-events: none;
          background-color: #f6f6f5;
          color: #72716c;
          text-decoration: none;
        }
      }
    }
  }

  .legend {
    margin-top: 16px;

    .legend-item {
      display: inline-block;
      font-size: 0.8125rem;
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
      margin-right: 0.25rem;

      &.available {
        background-color: #cfedcf;
        color: #1b3732;
        text-decoration: underline;
      }

      &.unavailable {
        background-color: rgba(154, 51, 0, 0.1);
        color: #9a3300;
      }

      &.fully-booked {
        background-color: #f6f6f5;
        color: #72716c;
      }
    }
  }
`;

const SelectorContainer = styled.div`
  padding: 8px;
  border-radius: 5px;
  background: #ffffff;
`;

const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
