/* eslint-disable react-hooks/exhaustive-deps */
import styled from 'styled-components';
import Select, { CSSObjectWithLabel } from 'react-select';
import { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

import { TextInput } from '../../../components/text-input/text-input';
import { AddressDetails, Floor } from '../types/addresses.types';
import { useAddress, toFloorValue } from '../hooks/useAddress';
import { validationMessages } from './validation-messages';

// eslint-disable-next-line complexity
export const Address = ({
  className,
  address,
  changeMultipleFields,
  validations,
  validate
}: {
  className?: string;
  address: AddressDetails;
  changeMultipleFields: (data: Partial<AddressDetails>) => void;
  validations: Record<keyof AddressDetails, boolean>;
  validate: (name: keyof AddressDetails, address?: AddressDetails) => void;
}) => {
  const [streetSearch, setStreetSearch] = useState<string>('');
  const [streetNumberSearch, setStreetNumberSearch] = useState<string>('');
  const [floorSearch, setFloorSearch] = useState<string>('');
  const [apartmentSearch, setApartmentSearch] = useState<string>('');
  const [showStreetAndNumberLabel, setShowStreetAndNumberLabel] = useState<boolean>(false);
  const [showFloorAndApartmentLabel, setShowFloorAndApartmentLabel] = useState<boolean>(false);

  const {
    streets,
    fetchStreets,
    streetNumbers,
    fetchStreetNumbers,
    clearStreetNumbers,
    floors,
    fetchFloors,
    apartments,
    fetchApartments
  } = useAddress();

  useEffect(() => {
    if (streetSearch.length > 0) {
      fetchStreets(streetSearch);
    }
  }, [streetSearch]);

  useEffect(() => {
    if (address.street && streetNumberSearch) {
      fetchStreetNumbers(address.street.streetIds, streetNumberSearch);
    }
  }, [streetNumberSearch, address.street]);

  useEffect(() => {
    if (address.streetNo?.showHouseholds && address.streetNo) {
      fetchFloors(address.streetNo.deliveryPointId);
    }
  }, [address.streetNo?.showHouseholds, address.streetNo]);

  useEffect(() => {
    if (address.streetNo && address.floor?.floorType && address.floor?.floorNo) {
      fetchApartments({
        deliveryPointId: address.streetNo.deliveryPointId,
        floorNo: address.floor.floorNo,
        floorType: address.floor.floorType
      });
    }
  }, [address.floor?.floorType, address.floor?.floorNo, address.streetNo?.deliveryPointId]);

  const getInputStyles = (isValid: boolean) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: (styles: CSSObjectWithLabel, state: any) => ({
      ...styles,
      height: 38,
      fontSize: 16,
      borderRadius: 8,
      '&:hover': {
        border: state.isFocused ? `2px solid #1B3732` : '2px solid #14120a1a',
        background: state.isFocused ? `2px solid white` : `#14120a08`
      },
      border: isValid ? `2px solid #14120a1a` : state.isFocused ? `2px solid #1B3732` : `2px solid #9a3300`,
      background: isValid ? `#ffffff` : `rgba(154, 51, 0, 0.1)`,
      outline: 'none !important',
      boxShadow: 'none',
      cursor: state.isDisabled ? 'not-allowed' : 'pointer'
    })
  });

  const updateStreetQuery = (query: string) => setStreetSearch(query);
  const debouncedStreetQueryChange = debounce(updateStreetQuery, 300);

  const updateStreetNumberQuery = (query: string) => setStreetNumberSearch(query);
  const debouncedStreetNumberQueryChange = debounce(updateStreetNumberQuery, 300);

  return (
    <StyledAddress>
      <Headline>Kontaktinformasjon</Headline>
      <TextInput
        placeholder='Fornavn'
        value={address.firstName}
        onChange={value => changeMultipleFields({ firstName: value })}
        valid={validations.firstName}
        onBlur={() => validate('firstName')}
        validationMessage={validationMessages.firstName}
        name='given-name'
        id='given-name'
      />
      <TextInput
        placeholder='Etternavn'
        value={address.lastName}
        onChange={value => changeMultipleFields({ lastName: value })}
        valid={validations.lastName}
        onBlur={() => validate('lastName')}
        validationMessage={validationMessages.lastName}
        name='family-name'
        id='family-name'
      />
      <TextInput
        placeholder='Telefonnummer'
        value={address.phoneNumber}
        onChange={value => changeMultipleFields({ phoneNumber: value })}
        valid={validations.phoneNumber}
        onBlur={() => validate('phoneNumber')}
        validationMessage={validationMessages.phoneNumber}
        name='tel'
        id='tel'
      />
      <TextInput
        placeholder='Email'
        value={address.email}
        onChange={value => changeMultipleFields({ email: value })}
        valid={validations.email}
        onBlur={() => validate('email')}
        validationMessage={validationMessages.email}
        name='email'
        id='email'
        type='email'
      />
      <SecondHeadline>Hvor og når skal vi levere?</SecondHeadline>
      <StreetAndNumberContainer>
        <StreetContainer>
          <SelectLabel className={showStreetAndNumberLabel ? 'showLabel' : ''}>Gatenavn</SelectLabel>
          <Select
            className={`select ${!validations.street ? 'invalid' : ''}`}
            onInputChange={debouncedStreetQueryChange}
            options={streets}
            blurInputOnSelect={false}
            onChange={option => {
              changeMultipleFields({
                street: option?.meta || null,
                streetNo: null,
                floor: null,
                apartment: null
              });

              validate('street', { ...address, street: option?.meta || null, streetNo: null, floor: null, apartment: null });

              clearStreetNumbers();
              setStreetNumberSearch('');
              setFloorSearch('');
              setApartmentSearch('');
            }}
            onBlur={() => validate('street')}
            onFocus={() => setShowStreetAndNumberLabel(true)}
            styles={getInputStyles(validations.street)}
            placeholder='Gatenavn'
            noOptionsMessage={() => 'skriv for å søke'}
            filterOption={() => true}
          />
          {!validations.street && <ErrorMessage>{validationMessages.street}</ErrorMessage>}
        </StreetContainer>
        <StreetNumberContainer>
          <SelectLabel className={showStreetAndNumberLabel ? 'showLabel' : ''}>Nr</SelectLabel>
          <Select
            className={`select ${!validations.streetNo ? 'invalid' : ''}`}
            isDisabled={!address.street}
            onInputChange={debouncedStreetNumberQueryChange}
            options={streetNumbers}
            blurInputOnSelect={false}
            onChange={option => {
              changeMultipleFields({
                streetNo: option?.meta || null,
                floor: null,
                apartment: null
              });

              validate('streetNo', { ...address, streetNo: option?.meta || null, floor: null, apartment: null });

              setFloorSearch('');
              setApartmentSearch('');
            }}
            onBlur={() => validate('streetNo')}
            onFocus={() => setShowStreetAndNumberLabel(true)}
            styles={getInputStyles(validations.streetNo)}
            placeholder='Nr'
            noOptionsMessage={() => 'skriv for å søke'}
            filterOption={() => true}
            value={
              address.streetNo?.deliveryPointId
                ? streetNumbers.filter(({ value }) => value === address.streetNo?.deliveryPointId)
                : null
            }
          />
          {!validations.streetNo && <ErrorMessage>{validationMessages.streetNo}</ErrorMessage>}
        </StreetNumberContainer>
      </StreetAndNumberContainer>

      {address.streetNo?.showHouseholds && (
        <FloorAndApartmentContainer>
          <FloorContainer>
            <SelectLabel className={showFloorAndApartmentLabel ? 'showLabel' : ''}>Etasje</SelectLabel>
            <Select
              className={`select ${!validations.floor ? 'invalid' : ''}`}
              inputValue={floorSearch}
              onInputChange={query => setFloorSearch(query)}
              options={floors}
              blurInputOnSelect={false}
              onChange={option => {
                changeMultipleFields({
                  floor: option?.meta || null,
                  apartment: null
                });

                validate('floor', { ...address, floor: option?.meta || null, apartment: null });

                setApartmentSearch('');
              }}
              onBlur={() => validate('floor')}
              onFocus={() => setShowFloorAndApartmentLabel(true)}
              styles={getInputStyles(validations.floor)}
              placeholder='Etasje'
              noOptionsMessage={() => 'lasting...'}
              filterOption={() => true}
              value={
                address.floor !== null ? floors.filter(({ value }) => toFloorValue(address.floor as Floor) === value) : null
              }
            />
            {!validations.floor && <ErrorMessage>{validationMessages.floor}</ErrorMessage>}
          </FloorContainer>
          <ApartmentContainer>
            <SelectLabel className={showFloorAndApartmentLabel ? 'showLabel' : ''}>Leilighet</SelectLabel>
            <Select
              className={`select ${!validations.apartment ? 'invalid' : ''}`}
              isDisabled={!address.floor}
              inputValue={apartmentSearch}
              onInputChange={query => setApartmentSearch(query)}
              options={apartments}
              blurInputOnSelect={false}
              onChange={option => {
                changeMultipleFields({ apartment: option?.meta || null });
                validate('apartment', { ...address, apartment: option?.meta || null });
              }}
              onBlur={() => validate('apartment')}
              onFocus={() => setShowFloorAndApartmentLabel(true)}
              styles={getInputStyles(validations.apartment)}
              placeholder='Leilighet'
              noOptionsMessage={() => 'lasting...'}
              filterOption={() => true}
              value={
                address.apartment ? apartments.filter(({ value }) => value === address.apartment?.deliveryPointId) : null
              }
            />
            {!validations.apartment && <ErrorMessage>{validationMessages.apartment}</ErrorMessage>}
          </ApartmentContainer>
        </FloorAndApartmentContainer>
      )}
    </StyledAddress>
  );
};

const StyledAddress = styled.div`
  margin-top: 8px;

  .select {
    margin-bottom: 12px;
    font-size: 16px;

    &.invalid {
      margin-bottom: 4px;
    }
  }
`;

const SelectLabel = styled.label`
  font-size: 14px;
  margin-bottom: 4px;
  visibility: hidden;

  &.showLabel {
    visibility: visible;
  }
`;

const StreetAndNumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StreetContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  margin-right: 20px;
`;

const StreetNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
`;

const FloorAndApartmentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const FloorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-right: 20px;
`;

const ApartmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const ErrorMessage = styled.div`
  font-size: 13px;
  color: #9a3300;
  margin-bottom: 12px;
`;

const Headline = styled.div`
  color: #1b3732;
  font-size: 16px;
  margin-bottom: 8px;
`;

const SecondHeadline = styled(Headline)`
  margin-top: 32px;
`;
