import React, { useEffect } from 'react';
import { useParams } from 'react-router';

import { trackPage } from './analytics/analytics';
import { PageParams } from './types/page-params';

export function Cancel({ orderName }: { orderName: string }) {
  const { vendorId } = useParams<PageParams>();

  useEffect(() => {
    trackPage();
  }, []);

  return (
    <div className='cancel'>
      <strong>Beklager, men noe gikk galt under behandlingen av din bestilling.</strong>
      <br />
      <br />
      <span>
        Vennligst forsøk igjen eller ta kontakt med Kondomeriet på <strong>kundeservice@kondomeriet.no</strong>
      </span>
    </div>
  );
}
