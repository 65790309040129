import React from 'react';
import styled from 'styled-components';

export const HeaderDeliveryInformation = ({ className }: { className?: string }) => {
  return <StyledHeaderDeliveryInformation className={className}>Frakt er inkludert</StyledHeaderDeliveryInformation>;
};

const StyledHeaderDeliveryInformation = styled.div`
  font-size: 15px;
  font-style: italic;
  color: #72716c;
  margin-top: 8px;
`;
