import React, { ReactNode } from 'react';
import { FontFace } from './font-face';
import { GlobalStyles } from './global-styles';

export const WithMorgenleveringTheme = ({ children }: { children: ReactNode }) => (
  <>
    <FontFace />
    <GlobalStyles />
    {children}
  </>
);
