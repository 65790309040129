/* eslint-disable max-depth */
/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation, withRouter, useParams } from 'react-router-dom';

import { Title } from '../common/title';
import { Price } from '../common/price';
import { HeaderDescription } from '../common/header-description';
import { Address } from '../address/address';
import { createOrder, getProduct } from '../client/morgenevering-client';
import { PurchaseButton } from '../../../components/purchase-button/purchase-button';
import { useDeliveryDates } from '../date-picker/use-delivery-dates';
import { AddressDetails } from '../types/addresses.types';
import { EventCategory, EventLabel, EventName, trackEvent, trackPage } from '../../../analytics/analytics';
import { MorgenleveringProduct } from '../types/morgenlevering-product.type';
import { MorgenleveringLogo } from '../common/morgenlevering-logo';
import { mapDataToOrderDto } from '../../order/map-data-to-order-dto';
import { GetDeliveryDatesRequestDto } from '../types/delivery-date.type';
import ChevronLeft from '../../../assets/chevron-left.svg';
import { useValidation } from '../hooks/useValidation';
import { CloseButton } from '../common/close-button';
import { PageParams } from '../../../types/page-params';
import { WithMorgenleveringTheme } from '../theme/with-morgenlevering-theme';
import { Card } from '../layout/card';
import { DatePicker } from '../date-picker/date-picker';

interface CustomizedFormViewState {
  product: MorgenleveringProduct;
}

export const FormView = () => {
  const [isPurchased, setIsPurchased] = useState<boolean>(false);
  const [isAddressInRegion, setIsAddressInRegion] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [product, setProduct] = useState<MorgenleveringProduct | null>(null);

  const { productId } = useParams<PageParams>();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const state = location.state as CustomizedFormViewState;

    if (state?.product) {
      setProduct(state.product);
    } else if (productId) {
      getProduct(productId)
        .then(product => setProduct(product))
        .catch(() => setErrorMessage('Produktet kunne ikke lastes ned. Vær så snill, prøv på nytt.'));
    }
  }, [productId, location]);

  const {
    deliveryDates,
    fetchDeliveryDates,
    selectedDeliveryDate,
    selectDeliveryDate,
    setClosestAvailableDate,
    clearDeliveryDates,
    isLoadingDates
  } = useDeliveryDates(product?.id);

  const [address, setAddress] = useState<AddressDetails>({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    street: null,
    streetNo: null,
    floor: null,
    apartment: null
  });

  const { validate, validations, isFormValid } = useValidation({ address, isAddressInRegion });

  const fetchDates = async (address: GetDeliveryDatesRequestDto) => {
    try {
      const dates = await fetchDeliveryDates(address);
      setClosestAvailableDate(dates);
    } catch (e) {
      setIsAddressInRegion(false);
    }
  };

  useEffect(() => {
    trackPage();
  }, []);

  useEffect(() => {
    if (
      (address.street && address.streetNo && address.streetNo.showHouseholds && address.floor && address.apartment) ||
      (address.street && address.streetNo && !address.streetNo.showHouseholds)
    ) {
      const { street, streetNo, floor, apartment } = address;

      const formattedFloorNo = floor ? (floor.floorNo.toString().length < 2 ? `0${floor.floorNo}` : `${floor.floorNo}`) : '';
      const formattedFlatNo = apartment
        ? apartment.flatNo.toString().length < 2
          ? `0${apartment.flatNo}`
          : `${apartment.flatNo}`
        : '';

      const apartmentNumber = apartment && floor ? `${floor.floorType}${formattedFloorNo}${formattedFlatNo}` : '';
      const streetAddress = `${street.streetName} ${streetNo.streetNo}${streetNo.entrance ? streetNo.entrance : ''}${
        apartmentNumber ? ` ${apartmentNumber}` : ''
      }`;

      fetchDates({
        countryCode: 'NO',
        postalName: address.street.city,
        zipCode: address.streetNo.postalCode,
        streetAddress,
        recipient: '-'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    address.street?.city,
    address.street?.streetName,
    address.streetNo?.streetNo,
    address.streetNo?.showHouseholds,
    address.streetNo?.entrance,
    address.floor?.floorNo,
    address.floor?.floorType,
    address.apartment?.flatNo
  ]);

  const changeAddressFields = (fieldValuesMap: Partial<AddressDetails>) => {
    setAddress({ ...address, ...fieldValuesMap });

    maybeResetDeliveryDates(fieldValuesMap);
  };

  const maybeResetDeliveryDates = (fieldValuesMap: Partial<AddressDetails>) => {
    const newAddressMapKeys = Object.keys(fieldValuesMap);
    if (
      newAddressMapKeys.includes('street') ||
      newAddressMapKeys.includes('streetNo') ||
      newAddressMapKeys.includes('floor') ||
      newAddressMapKeys.includes('apartment')
    ) {
      clearDeliveryDates();
      setIsAddressInRegion(true);
    }
  };

  const makeOrder = async (e: React.FormEvent) => {
    e.preventDefault();

    trackEvent({
      eventName: EventName.PAY_BUTTON_CLICK,
      label: EventLabel.PAY,
      category: EventCategory.CLICK
    });

    if (product && isFormValid()) {
      trackEvent({
        eventName: EventName.PAY_BUTTON_CLICK,
        label: EventLabel.PAY,
        category: EventCategory.CLICK
      });

      try {
        const roundedPrice = Math.round((parseFloat(product.price) + Number.EPSILON) * 100) / 100;

        const orderDto = mapDataToOrderDto(address, { id: product.id, price: roundedPrice }, selectedDeliveryDate);

        setIsPurchased(true);
        const { redirectUrl } = await createOrder(orderDto);
        window.location.href = redirectUrl;
      } catch (e) {
        const error = e as Error;
        if (error.message === 'PRODUCT_NOT_AVAILABLE') {
          setErrorMessage('Produktet er ikke tilgjengelig');
        } else {
          setErrorMessage('Betalingen ble ikke igangsatt. Prøv en gang til');
        }
        setIsPurchased(false);
      }
    } else {
      trackEvent({
        eventName: EventName.PAY_DISABLED_BUTTON_CLICK,
        label: EventLabel.PAY_DISABLED,
        category: EventCategory.CLICK
      });
    }
  };

  return (
    <WithMorgenleveringTheme>
      <Card errorTitle={errorMessage}>
        <div>
          <MorgenleveringLogo />
          <CloseButton />

          <ReturnButton
            onClick={() => {
              trackEvent({
                eventName: EventName.BACK_BUTTON_CLICK,
                label: EventLabel.BACK,
                category: EventCategory.CLICK
              });

              history.goBack();
            }}
          >
            <ChevronLeftIcon>
              <img src={ChevronLeft} alt='return icon' />
            </ChevronLeftIcon>
            <ReturnButtonHeader>Tilbake til produktsiden</ReturnButtonHeader>
          </ReturnButton>

          <Title title={product?.title} />

          <HeaderDescription />

          <Price price={product?.price || ''} />

          <form onSubmit={makeOrder}>
            <Address
              address={address}
              changeMultipleFields={changeAddressFields}
              validations={validations}
              validate={validate}
            />

            <DatePicker
              deliveryDates={deliveryDates}
              selectedDeliveryDate={selectedDeliveryDate}
              onChange={date => selectDeliveryDate(date)}
              isLoading={isLoadingDates}
              disabled={
                !isAddressInRegion || address.streetNo?.showHouseholds
                  ? address.apartment === null
                  : address.streetNo === null
              }
            />

            <br />
            <br />
            <ButtonWrapper>
              <PurchaseButton disabled={!product || isPurchased} isLoading={isPurchased} />
            </ButtonWrapper>
          </form>
        </div>
      </Card>
    </WithMorgenleveringTheme>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ReturnButtonHeader = styled.p`
  font-size: 14px;
  margin-left: 10px;
`;

const ReturnButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
  width: fit-content;
`;

const ChevronLeftIcon = styled.span`
  transform: translate(0px, 1px);
`;

export const FormViewWithRouter = withRouter(FormView);
