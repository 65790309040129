/* eslint-disable */
// @ts-nocheck

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import PulseLoader from 'react-spinners/PulseLoader';
import { css } from '@emotion/react';
import { Success } from './Success';
import { Cancel } from './Cancel';
import { SuccessPageParams } from './types/page-params';

import kondomeriet_logo from './assets/kondomeriet-logo.png';
const override = css`
  display: block;
  margin: 0 auto;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  top:0;
  right: 0;
  width: 100%;
  padding-top: 16px;
`;
const verifyPayment = async ({ orderId, vendorId, apiUrl }: { orderId: string; vendorId: string; apiUrl: string }) =>
  await axios({
    method: 'GET',
    url: `${apiUrl}/vipps/status/${orderId}/vendors/${vendorId}`
  }).catch(() => alert('something went wrong'));

function SuccessPage() {
  const { productId, orderId, variantId, vendorId } = useParams<SuccessPageParams>();
  const [status, setStatus] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState({
    product: {
      images: [],
      variants: [{ id: 0, name: '', price: 0, compare_at_price: 0 }]
    }
  });
  const api_url = 'https://api.ministore.seals.schibsted.io';

  useEffect(() => {
    async function fetchData() {
      const result = await axios({
        method: 'GET',
        url: `${api_url}/products/${productId}/vendors/${vendorId}/`
      }).catch(() => {
        setError(true);
      });
      setData(result.data);
    }

    fetchData().catch(() => {
      setError(true);
    });
  }, [productId]);

  useEffect(() => {
    async function verifyStatus() {
      if (orderId && vendorId) {
        console.log('check status');
        const statusRequest = await verifyPayment({ orderId, apiUrl: api_url, vendorId });
        setStatus(statusRequest.data);
      }
    }
    verifyStatus();
  }, [orderId, vendorId, api_url]);

  return (
    <div className='ProductPage' >
      <img src={kondomeriet_logo} width="150" />
      <div className="product">
        <img src={`${data.product.mainImage}?width=180&quality=80`} className="product_image" alt={data.product.title} />
        <div className='description'>
          <a href={`${data.product.url}`} target="_blank">{data.product.title}</a>
          {parseInt(data.product.compare_at_price,10)!==parseInt(data.product.price,10) && <div className='price_before'>{data.product.price},-</div>}
              <div className='price_after'>{data.product.compare_at_price},-</div>
        </div>
        <div className='button-wrap'>
          {status.transactionLogHistory && status.transactionLogHistory[0].operation === 'SALE' && (
            <Success orderName={orderId} />
          )}
          {status.transactionLogHistory && status.transactionLogHistory[0].operation === 'CAPTURE' && (
            <Success orderName={orderId} />
          )}
          {status.transactionLogHistory && status.transactionLogHistory[0].operation === 'RESERVE' && (
            <Success orderName={orderId} />
          )}
          {status.transactionLogHistory && status.transactionLogHistory[0].operation === 'CANCEL' && (
            <Cancel orderName={orderId} />
          )}
          <PulseLoader css={override} size={15} color={'white'} loading={false} />
        </div>
      </div>
    </div>
  );
}

export default SuccessPage;
