/* eslint-disable no-useless-escape */
/* eslint-disable complexity */
import { useState } from 'react';

import { AddressDetails } from '../types/addresses.types';

interface useValidationProps {
  address: AddressDetails;
  isAddressInRegion: boolean;
}

export const useValidation = ({ address, isAddressInRegion }: useValidationProps) => {
  const [isFirstNameValid, setIsFirstNameValid] = useState<boolean>(true);
  const [isLastNameValid, setIsLastNameValid] = useState<boolean>(true);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState<boolean>(true);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [isStreetValid, setIsStreetValid] = useState<boolean>(true);
  const [isStreetNoValid, setIsStreetNoValid] = useState<boolean>(true);
  const [isFloorValid, setIsFloorValid] = useState<boolean>(true);
  const [isApartmentValid, setIsApartmentValid] = useState<boolean>(true);

  /*
    Runs the validation, sets validation flags in state.

    @param name The name of the data key to be validated
    @param addressDetails Allows to pass the data to be validated
   */
  const validate = (name: keyof AddressDetails, addressDetails?: AddressDetails): boolean => {
    const dataForValidation = addressDetails ? addressDetails : address;

    if (['firstName', 'lastName'].includes(name)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const isValid = typeof dataForValidation[name] === 'string' && dataForValidation[name].length > 1;
      if (name === 'firstName') setIsFirstNameValid(isValid);
      if (name === 'lastName') setIsLastNameValid(isValid);

      if (!isValid) {
        return false;
      }
    }

    if (name === 'email') {
      const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      const isValid = mailFormat.test(dataForValidation[name]);
      setIsEmailValid(isValid);

      if (!isValid) {
        return false;
      }
    }

    if (name === 'phoneNumber') {
      const phoneFormat = /^(0047|\+47|47)?[2-9]\d{7}$/;
      const isValid = phoneFormat.test(dataForValidation[name]);
      setIsPhoneNumberValid(isValid);

      if (!isValid) {
        return false;
      }
    }

    if (
      ['street', 'streetNo'].includes(name) ||
      (dataForValidation.streetNo?.showHouseholds && ['street', 'streetNo', 'floor', 'apartment'].includes(name))
    ) {
      const isValid = dataForValidation[name] !== null;
      if (name === 'street') setIsStreetValid(isValid);
      if (name === 'streetNo') setIsStreetNoValid(isValid);
      if (name === 'floor') setIsFloorValid(isValid);
      if (name === 'apartment') setIsApartmentValid(isValid);

      if (!isValid) {
        return false;
      }
    }

    return true;
  };

  const isFormValid = () => {
    const isFirstNameValid = validate('firstName');
    const isLastNameValid = validate('lastName');
    const isNumberValid = validate('phoneNumber');
    const isEmailValid = validate('email');
    const isStreetValid = validate('street');
    const isStreetNoValid = validate('streetNo');
    const isFloorValid = address.streetNo?.showHouseholds ? validate('floor') : true;
    const isApartmentValid = address.streetNo?.showHouseholds ? validate('apartment') : true;

    return (
      isFirstNameValid &&
      isLastNameValid &&
      isNumberValid &&
      isEmailValid &&
      isStreetValid &&
      isStreetNoValid &&
      isFloorValid &&
      isApartmentValid &&
      isAddressInRegion
    );
  };

  const validations: Record<keyof AddressDetails, boolean> = {
    firstName: isFirstNameValid,
    lastName: isLastNameValid,
    phoneNumber: isPhoneNumberValid,
    email: isEmailValid,
    street: isStreetValid && isAddressInRegion,
    streetNo: isStreetNoValid,
    floor: isFloorValid,
    apartment: isApartmentValid
  };

  return {
    validate,
    validations,
    isFormValid
  };
};
