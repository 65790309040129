import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    html, body {
        background: #fffdf5;
    }

    html, body, button, input {
        font-family: Agipo, sans-serif;
    }
`;
