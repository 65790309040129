import { AddressDetails, completedAddressDetailsGuard } from '../morgenlevering/types/addresses.types';
import { CreateOrderDto } from '../morgenlevering/types/order.types';

export const mapDataToOrderDto = (
  address: AddressDetails,
  product: { id: number; price: number },
  deliveryDate: string
): CreateOrderDto => {
  const REGION_ID = 7;

  if (!completedAddressDetailsGuard(address)) {
    throw new Error('Address details not completed');
  }

  const deliveryPointId = address.streetNo.showHouseholds
    ? address.apartment?.deliveryPointId
    : address.streetNo.deliveryPointId;
  if (!deliveryPointId) {
    throw new Error('Missing delivery point ID');
  }

  return {
    customer: {
      firstName: address.firstName,
      lastName: address.lastName,
      mobile: address.phoneNumber,
      email: address.email
    },
    address: {
      regionId: REGION_ID,
      zipCode: address.streetNo.postalCode,
      postalName: address.street.city,
      streetName: address.street.streetName,
      streetNumber: address.streetNo.streetNo,
      entrance: address.streetNo.entrance || '',
      floorType: address.floor ? address.floor.floorType : undefined,
      floorNumber: address.floor ? address.floor.floorNo : undefined,
      flatNumber: address.floor ? address.apartment?.flatNo : undefined,
      deliveryPointId
    },
    deliveryDate,
    product: {
      id: product.id,
      price: product.price
    }
  };
};
