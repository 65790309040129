import { useState } from 'react';

import { getApartments, getFloors, getStreetNames, getStreetNumbers } from '../client/morgenevering-client';
import { Floor, Household, Street, StreetNumber } from '../types/addresses.types';

const capitalise = (text: string) => `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`;

export const toFloorValue = (floor: Floor): string => `${floor.floorNo}-${floor.floorType}`;

export const useAddress = () => {
  const [streets, setStreets] = useState<{ label: string; value: number; meta: Street }[]>([]);
  const [streetNumbers, setStreetNumbers] = useState<{ label: string; value: number; meta: StreetNumber }[]>([]);
  const [floors, setFloors] = useState<{ label: string; value: string; meta: Floor }[]>([]);
  const [apartments, setApartments] = useState<{ label: string; value: number; meta: Household }[]>([]);

  const fetchStreets = async (query: string) => {
    setStreets([]);
    const response = await getStreetNames(query);

    setStreets(
      response.map(street => ({
        label: `${capitalise(street.streetName)}, ${capitalise(street.city)}`,
        value: street.streetIds[0],
        meta: street
      }))
    );
  };

  const fetchStreetNumbers = async (streetIds: number[], query: string) => {
    setStreetNumbers([]);
    const response = await getStreetNumbers(streetIds, query);

    setStreetNumbers(
      response.map(streetNumber => ({
        label: `${streetNumber.streetNo}${streetNumber.entrance || ''}`,
        value: streetNumber.deliveryPointId,
        meta: streetNumber
      }))
    );
  };

  const clearStreetNumbers = () => setStreetNumbers([]);

  const fetchFloors = async (deliveryPointId: number) => {
    setFloors([]);
    const response = await getFloors(deliveryPointId);

    const mapFlorType = (florType: string) => {
      switch (florType) {
        case 'H':
          return 'etasje';
        case 'U':
          return 'underetasje';
        case 'L':
          return 'etasje (loft)';
        case 'K':
          return 'kjeller';
        default:
          return '';
      }
    };

    setFloors(
      response.map(floor => ({
        label: `${floor.floorNo}. ${mapFlorType(floor.floorType)}`,
        value: toFloorValue(floor),
        meta: floor
      }))
    );
  };

  const fetchApartments = async ({
    deliveryPointId,
    floorNo,
    floorType
  }: {
    deliveryPointId: number;
    floorNo: number;
    floorType: string;
  }) => {
    setApartments([]);
    const response = await getApartments({ deliveryPointId, floorNo, floorType });

    const formatLabel = ({
      floor,
      flatNoAlias,
      floorType,
      flatNo
    }: {
      floor: number;
      flatNoAlias: string;
      floorType: string;
      flatNo: number;
    }) => {
      const formattedFloorNo = floor.toString().length < 2 ? `0${floor}` : `${floor}`;
      const formattedFlatNo = flatNo.toString().length < 2 ? `0${flatNo}` : `${flatNo}`;
      const formattedFlatAlias = flatNoAlias ? ` (${flatNoAlias.trim()})` : '';
      return `${floorType}${formattedFloorNo}${formattedFlatNo}${formattedFlatAlias}`;
    };

    setApartments(
      response.map(apartment => ({
        label: formatLabel({ floor: floorNo, floorType, flatNoAlias: apartment.flatNoAlias, flatNo: apartment.flatNo }),
        value: apartment.deliveryPointId,
        meta: apartment
      }))
    );
  };

  return {
    streets,
    fetchStreets,
    streetNumbers,
    fetchStreetNumbers,
    clearStreetNumbers,
    floors,
    fetchFloors,
    apartments,
    fetchApartments
  };
};
