/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as Sentry from '@sentry/browser';

import { getMonthName } from '../../../helpers/getMonthName';
import { isDateTomorrow } from '../../../helpers/isDateTomorrow';
import { getOrder, getPaymentStatus } from '../client/morgenevering-client';
import { CloseButton } from '../common/close-button';
import { MorgenleveringLogo } from '../common/morgenlevering-logo';
import { GetOrderResponse, GetPaymentStatusDto, PaymentStatus } from '../types/order.types';
import { EventCategory, EventLabel, EventName, trackEvent, trackPage } from '../../../analytics/analytics';
import { WithMorgenleveringTheme } from '../theme/with-morgenlevering-theme';
import { Card } from '../layout/card';
import { convertToUrlSafe } from '../converters/url-safe-convereter';

export const VendorMorgenleveringSuccessPage = () => {
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus | null>(null);
  const [productId, setProductId] = useState<string>('');
  const [order, setOrder] = useState<GetOrderResponse>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { search } = useLocation();
  const queryParams = React.useMemo(() => new URLSearchParams(search), [search]);
  const sessionId = queryParams.get('orderSession');
  const history = useHistory();

  useEffect(() => {
    trackPage();
  }, []);

  useEffect(() => {
    if (paymentStatus === PaymentStatus.CANCEL) {
      trackEvent({
        eventName: EventName.PAYMENT_FAILURE_VIEW_DISPLAYED,
        label: EventLabel.PAYMENT_FAILURE_VIEW,
        category: EventCategory.VIEW
      });
    }

    if (paymentStatus === PaymentStatus.SALE) {
      trackEvent({
        eventName: EventName.PAYMENT_SUCCESS_VIEW_DISPLAYED,
        label: EventLabel.PAYMENT_SUCCESS_VIEW,
        category: EventCategory.VIEW
      });
    }
  }, [paymentStatus]);

  useEffect(() => {
    const getPaymentDetails = async (sessionId: GetPaymentStatusDto) => {
      try {
        const { paymentStatus, productId } = await getPaymentStatus(sessionId);
        setPaymentStatus(paymentStatus);
        setProductId(productId);
      } catch (e) {
        setErrorMessage('Kunne ikke laste inn betalingsstatus.');
        Sentry.captureException(e, {
          contexts: {
            context: {
              view: 'success-page',
              info: 'FETCH_PAYMENT_INFO_FAILED',
              sessionId: sessionId,
              search,
              contextType: 'CUSTOM'
            }
          }
        });
      }
    };

    getPaymentDetails({ sessionId });
  }, [sessionId, search]);

  useEffect(() => {
    const fetchOrder = async (sessionId: string) => {
      try {
        const response = await getOrder(sessionId);
        setOrder(response);
      } catch (e) {
        setErrorMessage('Kunne ikke laste inn ordredetaljer.');
        Sentry.captureException(e, {
          contexts: {
            context: { view: 'success-page', info: 'GET_ORDER_FAILURE', sessionId: sessionId, search, contextType: 'CUSTOM' }
          }
        });
      }
    };

    if (sessionId) {
      fetchOrder(sessionId);
    }
  }, [sessionId, search]);

  const handleStoreClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    trackEvent({
      eventName: EventName.SUCCESS_PAGE_CLOSE_AFTER_PURCHASE_BUTTON_CLICK,
      label: EventLabel.CLOSE,
      category: EventCategory.CLICK
    });

    window.parent.postMessage('close_ministore', '*');
  };

  const camelize = (str: string) => {
    return (' ' + str).toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, function (match, chr) {
      return chr.toUpperCase().replace(/([A-Z])/g, ' $1');
    });
  };

  const street = camelize(order?.deliveries[0].recipient.streetName || '');
  const streetNo = order?.deliveries[0].recipient.streetNo;
  const city = camelize(order?.deliveries[0].recipient.city || '');
  const deliveryAddress = `${street} ${streetNo}, ${city}`;

  const productName = order?.deliveries[0].products[0].title;
  const price = order?.payment.grossAmount;

  const deliveryDate = order?.deliveryDate ? new Date(order?.deliveryDate) : null;
  const formattedDeliveryDate = `${deliveryDate?.getDate()}. ${getMonthName(deliveryDate?.getMonth())}`;

  const imageUrl = order?.productImage.src;

  return (
    <WithMorgenleveringTheme>
      {sessionId && (
        <Card errorTitle={errorMessage}>
          <div>
            <MorgenleveringLogo />
            <CloseButton />
            {paymentStatus === PaymentStatus.CANCEL && (
              <>
                <ConfirmationHeader>Beklager, noe gikk galt</ConfirmationHeader>
                <ErrorDescription>
                  Betalingen ble ikke registrert på grunn av noe skurr i maskineriet. Vennligst prøv en gang til
                </ErrorDescription>
                <GoBackButtonWrapper>
                  <GoBackButton
                    onClick={() => {
                      trackEvent({
                        eventName: EventName.FAILURE_PAGE_BACK_TO_ORDER_BUTTON_CLICK,
                        label: EventLabel.FAILURE_BACK_TO_ORDER,
                        category: EventCategory.CLICK
                      });

                      history.push(`/morgenlevering/${productId}`);
                    }}
                  >
                    Tilbake til bestillingen
                  </GoBackButton>
                </GoBackButtonWrapper>
              </>
            )}
            {paymentStatus === PaymentStatus.SALE && (
              <SaleConfirmationWrapper>
                <ConfirmationHeader>Takk for handelen!</ConfirmationHeader>
                <ConfirmationDescription>
                  <strong>{isDateTomorrow(deliveryDate || null) ? 'I morgen' : formattedDeliveryDate}</strong> leverer vi
                  litt morgenmagi til {deliveryAddress}
                </ConfirmationDescription>
                <YourOrderInfo>Din bestilling:</YourOrderInfo>
                <OrderSummary>
                  <p>{productName}</p>
                  <Price>{price},- (inkl. frakt)</Price>
                </OrderSummary>
                {imageUrl ? (
                  <ProductImage src={imageUrl} alt={order.productImage.alt} />
                ) : (
                  <ProductImageSkeletonContainer>
                    <Skeleton width={310} height={310} />
                  </ProductImageSkeletonContainer>
                )}
                <DiscountHeader>Rabatt på neste kjøp</DiscountHeader>
                <DiscountDescription>
                  Logg inn på{' '}
                  <TextLink
                    href={`https://morgenlevering.no?utm_source=ministore&utm_campaign=${convertToUrlSafe(
                      order?.deliveries[0].products[0].title
                    )}`}
                    target='_blank'
                  >
                    Morgenlevering.no
                  </TextLink>{' '}
                  og få 10% rabatt på ditt neste kjøp! Gjelder innloggede kunder til og med 31.08.22.
                </DiscountDescription>
                <DiscountCode>
                  Rabattode: <strong>YQATW8</strong>
                </DiscountCode>
                <GoBackButtonWrapper>
                  <GoBackButton onClick={handleStoreClose}>Tilbake</GoBackButton>
                </GoBackButtonWrapper>
              </SaleConfirmationWrapper>
            )}
          </div>
        </Card>
      )}
    </WithMorgenleveringTheme>
  );
};

const SaleConfirmationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: #1b3732;
`;

const GoBackButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ConfirmationHeader = styled.div`
  font-style: italic;
  font-weight: 700;
  font-size: 28px;
  margin-top: 30px;
  margin-bottom: 12px;
`;

const ErrorDescription = styled.div`
  font-weight: 400;
  font-size: 16px;
`;

const ConfirmationDescription = styled.div`
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 30px;
`;

const GoBackButton = styled.button`
  background: #ffe382;
  border-radius: 8px;
  padding: 16px 32px;
  border: none;
  color: #1b3732;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  margin-top: 40px;
  min-width: 247px;
`;

const YourOrderInfo = styled.strong`
  font-weight: 700;
  font-size: 16px;
`;

const OrderSummary = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: top;
  margin-bottom: 20px;
`;

const Price = styled.p`
  width: 65%;
  margin-left: 10px;
  text-align: right;
`;

const DiscountHeader = styled.strong`
  font-weight: 700;
  font-size: 16px;
`;

const DiscountDescription = styled.div`
  font-weight: 400;
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 20px;
`;

const DiscountCode = styled.div`
  font-size: 16px;
`;

const ProductImage = styled.img`
  margin-top: -50px;
  height: auto;
  width: 190px;
  margin-bottom: 30px;
`;

const ProductImageSkeletonContainer = styled.div`
  margin-top: -50px;
`;

const TextLink = styled.a`
  text-decoration: underline;
  transition: color 0.3s;
  color: #1b3732;

  &:hover {
    color: #72716c;
  }
`;
