import React from 'react';
import styled from 'styled-components';

import close from '../../../assets/close.svg';
import { EventCategory, EventLabel, EventName, trackEvent } from '../../../analytics/analytics';

export const CloseButton = ({ className }: { className?: string }) => {
  const handleStoreClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    trackEvent({
      eventName: EventName.CLOSE_BUTTON_CLICK,
      label: EventLabel.CLOSE,
      category: EventCategory.CLICK
    });

    window.parent.postMessage('close_ministore', '*');
  };

  return (
    <StyledCloseButton className={className} onClick={handleStoreClose}>
      <img src={close} alt='return icon' />
    </StyledCloseButton>
  );
};

const StyledCloseButton = styled.button`
  float: right;
  background: none;
  border: none;
  cursor: pointer;
`;
