import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

export const Price = ({ className, price }: { className?: string; price: string }) => {
  return (
    <StyledPrice className={className}>
      {price ? `${Math.round((parseFloat(price) + Number.EPSILON) * 100) / 100},-` : <Skeleton width={70} />}
    </StyledPrice>
  );
};

const StyledPrice = styled.span`
  display: inline-block;
  font-style: italic;
  font-size: 32px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
`;
