import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

export const Title = ({ className, title }: { className?: string; title?: string }) => (
  <StyledTitle className={className}>{title || <Skeleton height={20} />}</StyledTitle>
);

const StyledTitle = styled.h1`
  min-height: 1px;
  margin-top: 14px;
  margin-bottom: 8px;
  padding: 0;
  font-size: 20px;
  font-weight: 700;
  font-style: italic;
`;
