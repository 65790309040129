import React from 'react';
import styled from 'styled-components';

import { Annonse } from '../common/annonse';

export const Card = ({
  className,
  children,
  errorTitle
}: {
  className?: string;
  children: JSX.Element;
  errorTitle?: string | null;
}) => {
  return (
    <StyledCard className={className}>
      <Annonse />
      {errorTitle && <PurchaseError>{errorTitle}</PurchaseError>}
      <CardContent>{children}</CardContent>
    </StyledCard>
  );
};

const StyledCard = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  background: #fffdf5;
`;

const CardContent = styled.div`
  padding: 23px;
  overflow: auto;
  height: 100%;
`;

const PurchaseError = styled.div`
  background: #d85b40;
  font-size: 14px;
  color: #ffffff;
  padding: 10px 23px;
  text-align: center;
`;
