import googleAnalytics from '@analytics/google-analytics';
import Analytics from 'analytics';
import { envVariables } from '../env-variables';

export const isAnalyticsEnabled = envVariables.gaEnabled;

export const analytics = Analytics({
  app: 'Ministore',
  plugins: [
    googleAnalytics({
      trackingId: envVariables.gaId,
      anonymizeIp: true,
      debug: false,
      cookieConfig: {
        cookieName: 'gaCookie',
        cookieDomain: envVariables.cookieDomain,
        cookieFlags: 'SameSite=None; Secure'
      }
    })
  ]
});

export const trackPage = () => {
  if (!isAnalyticsEnabled) {
    return;
  }

  analytics.page();
};

export const trackEvent = (options: { eventName: string; category: string; label: string }) => {
  if (!isAnalyticsEnabled) {
    return;
  }

  analytics.track(options.eventName, { category: options.category, label: options.label });
};

export enum EventName {
  CLOSE_BUTTON_CLICK = 'close_button_click',
  CLOSE_OVERLAY_CLICK = 'close_overlay_click',
  ORDER_BUTTON_CLICK = 'order_button_click',
  PAY_BUTTON_CLICK = 'pay_button_click',
  PAY_DISABLED_BUTTON_CLICK = 'pay_disabled_button_click',
  BACK_BUTTON_CLICK = 'back_button_click',
  ML_LINK_CLICK = 'ml_link_click',
  SUCCESS_PAGE_CLOSE_AFTER_PURCHASE_BUTTON_CLICK = 'close_after_purchase_button_click',
  FAILURE_PAGE_BACK_TO_ORDER_BUTTON_CLICK = 'back_to_order_on_failure_button_click',
  PAYMENT_SUCCESS_VIEW_DISPLAYED = 'payment_success_view_displayed',
  PAYMENT_FAILURE_VIEW_DISPLAYED = 'payment_failure_view_displayed'
}

export enum EventCategory {
  CLICK = 'click',
  VIEW = 'view'
}

export enum EventLabel {
  BACK = 'back',
  CLOSE = 'close',
  ML_LINK = 'ml_link',
  ORDER = 'order',
  PAY = 'pay',
  PAY_DISABLED = 'pay_disabled',
  FAILURE_BACK_TO_ORDER = 'failure_back_to_order',
  PAYMENT_FAILURE_VIEW = 'payment_failure_view',
  PAYMENT_SUCCESS_VIEW = 'payment_success_view'
}
