import React from 'react';
import styled from 'styled-components';

import header_logo from '../../../assets/morgenlevering-logo.svg';

export const MorgenleveringLogo = ({ className }: { className?: string }) => {
  return <StyledMorgenleveringLogo className={className} src={header_logo} alt={`Morgenlevering logo`} />;
};

const StyledMorgenleveringLogo = styled.img`
  height: 75px;
  width: 75px;
  position: relative;
`;
