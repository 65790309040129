import { useState } from 'react';

import { getDeliveryDates } from '../client/morgenevering-client';
import { DeliveryCapacityStatus, DeliveryDate, GetDeliveryDatesRequestDto } from '../types/delivery-date.type';

type UseDeliveryDates = {
  deliveryDates: DeliveryDate[];
  fetchDeliveryDates: (address: GetDeliveryDatesRequestDto) => Promise<DeliveryDate[]>;
  selectedDeliveryDate: string;
  selectDeliveryDate: (date: string) => void;
  setClosestAvailableDate: (dates: DeliveryDate[]) => void;
  clearDeliveryDates: () => void;
  isLoadingDates: boolean;
};

export const useDeliveryDates = (productId?: number): UseDeliveryDates => {
  const [deliveryDates, setDeliveryDates] = useState<DeliveryDate[]>([]);
  const [selectedDeliveryDate, selectDeliveryDate] = useState<string>('');
  const [isLoadingDates, setLoadingDates] = useState<boolean>(false);

  const fetchDeliveryDates = async (address: GetDeliveryDatesRequestDto) => {
    if (!productId) {
      throw new Error('Product id is not a number');
    }

    setLoadingDates(true);
    try {
      const dates = await getDeliveryDates(address, productId);
      setDeliveryDates(dates);
      return dates;
    } catch (e) {
      throw e;
    } finally {
      setLoadingDates(false);
    }
  };

  const setClosestAvailableDate = (dates: DeliveryDate[]) => {
    const day = dates.find(date => date.availability === DeliveryCapacityStatus.AVAILABLE);
    selectDeliveryDate(day?.date || '');
  };

  const clearDeliveryDates = () => {
    setDeliveryDates([]);
    selectDeliveryDate('');
  };

  return {
    deliveryDates,
    fetchDeliveryDates,
    selectedDeliveryDate,
    selectDeliveryDate,
    setClosestAvailableDate,
    clearDeliveryDates,
    isLoadingDates
  };
};
