import React, { useState } from 'react';
import ReactModal from 'react-modal';

import { formatDate } from '../../../helpers/date';
import { DeliveryDate } from '../types/delivery-date.type';
import { DateSelector } from './date-selector';
import { TextInput } from '../../../components/text-input/text-input';
import { isDateTomorrow } from '../../../helpers/isDateTomorrow';

export const DatePicker = ({
  deliveryDates,
  selectedDeliveryDate,
  onChange,
  isLoading,
  disabled = false
}: {
  deliveryDates: DeliveryDate[];
  selectedDeliveryDate: string;
  onChange: (date: string) => void;
  isLoading: boolean;
  disabled?: boolean;
}) => {
  const [isPickerOpen, togglePicker] = useState<boolean>(false);

  const handleChange = (date: string) => {
    onChange(date);
    togglePicker(false);
  };

  return (
    <>
      <TextInput
        placeholder='Leveringsdag'
        value={
          selectedDeliveryDate && isDateTomorrow(new Date(selectedDeliveryDate))
            ? 'I morgen'
            : selectedDeliveryDate
            ? formatDate(selectedDeliveryDate)
            : ''
        }
        onKeyDown={(e: React.KeyboardEvent<HTMLElement>) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            togglePicker(true);
            e.stopPropagation();
          }
        }}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          togglePicker(true);
          e.stopPropagation();
        }}
        disabled={disabled}
        autoComplete='off'
        labelVisible={selectedDeliveryDate !== ''}
      />
      <ReactModal
        isOpen={isPickerOpen}
        style={{
          overlay: {
            backgroundColor: 'rgba(140, 140, 140, 0.75)'
          },
          content: {
            maxWidth: 330,
            marginLeft: 'auto',
            marginRight: 'auto',
            inset: 8,
            border: 0,
            background: 'transparent',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: 0
          }
        }}
        ariaHideApp={false}
      >
        <DateSelector
          deliveryDates={deliveryDates}
          onChange={handleChange}
          value={selectedDeliveryDate}
          onClickOutside={() => {
            togglePicker(false);
          }}
          isLoading={isLoading}
        />
      </ReactModal>
    </>
  );
};
