type EnvVariables = {
  apiUrl: string;
  cookieDomain: string;
  gaId: string;
  gtmId: string;
  gtmAuth: string;
  gtmPreview: string;
  gaEnabled: boolean;
  sentryDSN: string;
  sentryEnv: string;
  sentryTraceRate: number;
};

export const envVariables: EnvVariables = {
  apiUrl: process.env.REACT_APP_MINISTORE_API_URL || '',
  cookieDomain: process.env.REACT_APP_MINISTORE_COOKIE_DOMAIN || '',
  gaId: process.env.REACT_APP_MINISTORE_GA_ID || '',
  gtmId: process.env.REACT_APP_MINISTORE_GTM_ID || 'GTM-TNNXLMZ',
  gtmAuth: process.env.REACT_APP_MINISTORE_GTM_AUTH || '1FIyQ83f8DTvkbt-FMoZNA',
  gtmPreview: process.env.REACT_APP_MINISTORE_GTM_PREVIEW || 'env-1',
  gaEnabled: process.env.REACT_APP_MINISTORE_GA_ENABLED ? process.env.REACT_APP_MINISTORE_GA_ENABLED === 'true' : false,
  sentryDSN: process.env.REACT_APP_MINISTORE_SENTRY_DSN || '',
  sentryEnv: process.env.REACT_APP_MINISTORE_SENTRY_ENV || '',
  sentryTraceRate: process.env.REACT_APP_MINISTORE_TRACE_SAMPLE_RATE
    ? Number(process.env.REACT_APP_MINISTORE_TRACE_SAMPLE_RATE)
    : 0
};
