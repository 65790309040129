import React from 'react';

import header_logo from '../../assets/morgenlevering-logo.svg';

export const ErrorView = () => (
  <div className='ProductPage'>
    <div className='container'>
      <header className={`ProductHeader morgenlevering`}>
        <img src={header_logo} alt={`Morgenlevering logo`} />
      </header>
      <div className={'left'}>
        <h1>Opps…</h1>
        <div className={'product-price'} style={{ fontWeight: 'normal' }}>
          vi kan ikke finne produktet du er ute etter.
        </div>
      </div>
    </div>
  </div>
);
