import { AddressDetails } from '../types/addresses.types';

export const validationMessages: Record<keyof AddressDetails, string> = {
  firstName: 'Fornavn må fylles ut',
  lastName: 'Etternavn må fylles ut',
  phoneNumber: 'Telefonnummer må fylles ut',
  email: 'Email må fylles ut',
  street: 'Gatenavn må fylles ut (Vi leverer til Østlandet)',
  streetNo: 'Gatenummer må fylles ut',
  floor: 'Etasje må fylles ut',
  apartment: 'Leilighetsnummer må fylles ut'
};
