/* eslint-disable */

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import PulseLoader from 'react-spinners/PulseLoader';
import { css } from '@emotion/react';
import TagManager from 'react-gtm-module';
import { envVariables } from './env-variables';

import kondomeriet_logo from './assets/kondomeriet-logo.png';
import vippsButton from './assets/VippsHurtigkasse-210px.svg';

const override = css`
  display: block;
  margin: 0 auto;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  top:0;
  right: 0;
  width: 100%;
  padding-top: 16px;
`;

function ProductPage() {
  useEffect(() => {

  }, []);

  const api_url = 'https://api.ministore.helthjem.no';

  const { productId, vendorId, variantId } = useParams();
  const history = useHistory();
  const [data, setData] = useState({
    product: {
      images: [],
      variants: [{ id: 0, name: '', price: 0, compare_at_price: 0 }]
    }
  });
  const [openModal, setOpenModal] = useState(false);
  const [checked1, setChecked1] = useState('');
  const [checked2, setChecked2] = useState('');

  const [openPopup, setOpenPopup] = useState(true);
  const [error, setError] = useState(false);
  const [authorizePaymentInProgress, setAuthorizePaymentInProgress] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const result = await axios({
        method: 'GET',
        url: `${api_url}/products/${productId}/vendors/${vendorId}/`
      }).catch(() => {
        setError(true);
      });
      setData(result.data);
    }

    fetchData().catch(() => {
      setError(true);
    });
  }, [productId]);



  const makePayment = async () => {
    setAuthorizePaymentInProgress(true);
    // trackEvent({ eventName: 'payButtonClicked', category: 'Vipps Buton', label: 'vipps button clicked' });
    const finalPrice = parseInt(data.product.compare_at_price, 10) !== parseInt(data.product.price, 10) ? parseFloat(data.product.compare_at_price) : parseFloat(data.product.price)
    const req = await axios({
      method: 'POST',
      url: `${api_url}/vipps/vendors/${vendorId}`,
      data: {
        price: finalPrice,
        title: `${data.product.title}`,
        quantity: 1,
        product_id: productId,
        variant_id: parseInt(productId, 10)
      }
    }).catch(() => alert('payment failed'));

    if (req && (req.status === 200 || req.status === 201) && !req.hasOwnProperty('errorCode')) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'purchase',
          transaction_id: req.data.orderId,
          value: finalPrice.toFixed(2),
          currency: 'NOK',
            items: [
            {
              item_id: productId,
              item_name: `${data.product.title}`,
              price: finalPrice.toFixed(2),
              quantity: 1
            }
          ]
        }
      });
      // console.log(req.data);
      setTimeout( ()=>{
              window.location = req.data.url;
      },1000)
      // window.location = req.data.url;
    } else {
      alert('payment failed');
    }
  };

  const handleCloseModal = () => {
    // trackEvent({ eventName: 'modal', category: 'PrivacyModal', label: 'close' });

    setOpenModal(false);
  };

  const handleClosePopup = () => {

    const test = makePayment();
    console.log(test);
  };

  const toggleCheck = (e) => {
    e.preventDefault();
    if (checked1 == '') {
      setChecked1('checked');
    } else {
      setChecked1('');
    }

  }
  const toggleCheck2 = (e) => {
    e.preventDefault();

    if (checked2 == '') {
      setChecked2('checked');
    } else {
      setChecked2('');
    }
  }

  useEffect(() => {
    if (checked1 === 'checked' && checked2 === 'checked') {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [checked1, checked2]);


  const initializePayment = async () => {
    if (buttonDisabled === false) {
      await makePayment();
    }
  }
  return (
    <>
      {!error && (
        <div className='ProductPage'>
          <img src={kondomeriet_logo} width="150" />
          <div className="product">
            <img src={`${data.product.mainImage}?width=180&quality=80`} className="product_image" alt={data.product.title} />
            <div className='description'>
              <a href={`${data.product.url}?utm_source=vg&utm_medium=content&utm_content=womanizerduo&utm_campaign=kjopwomanizerduo`} target="_blank">{data.product.title}</a>
              {parseInt(data.product.compare_at_price, 10) !== parseInt(data.product.price, 10) && <div className='price_before'>{data.product.price},-</div>}
              <div className='price_after'>{data.product.compare_at_price},-</div>
            </div>
            <div className="vipps-express-checkout-page__text">
              <div className="formRow checkWrapper" onClick={toggleCheck}>
                <div className={`checkbox_square ${checked1}`}>
                  <input type="checkbox" id="concented" className="_jsCheckElement _jsCheckbox check-element" />
                </div>
                <label htmlFor="concented">
                  <p>Jeg bekrefter at jeg er over 18 år og aksepterer <a href="https://kondomeriet.no/inspirasjon/artikler/kjopsbetingelser/?utm_source=vg&utm_medium=content&utm_content=womanizerduo&utm_campaign=kjopwomanizerduo" target="_blank">kjøpsbetingelsene</a>.</p>
                </label>
              </div>
            </div>
            <div className="vipps-express-checkout-page__text">
              <div className="formRow checkWrapper" onClick={toggleCheck2}>
                <div className={`checkbox_square ${checked2}`}>
                  <input type="checkbox" id="concented" className="_jsCheckElement _jsCheckbox check-element" />
                </div>
                <label htmlFor="concented">
                  <p>Jeg aksepterer at porto basert på valgt leveringsmåte kommer i tillegg til beløpet over.</p>
                </label>
              </div>
            </div>
            <div className='button-wrap'>
              <a className={`vipps-express-checkout-page__vippsbutton ${!!buttonDisabled ? 'disabled' : ''} ${!!authorizePaymentInProgress ? 'init' : ''}`} onClick={initializePayment}>
                <img src={vippsButton} alt="Fortsett med Vipps" />
              </a>
              <PulseLoader css={override} size={15} color={'white'} loading={authorizePaymentInProgress} />
            </div>
          </div>
            <span data-TagManager={`${envVariables.gtmId}`}/>
        </div>
      )}
      {error && (
        <div className='ProductPage'>
          <div className='container'>
            {/*<ProductNamePrice product={data} variantId={currentVariant} mobile/>*/}
            <div className={'right'}>
              <h1>Opps…</h1>
              <div className={'product-price'} style={{ fontWeight: 'normal' }}>
                vi kan ikke finne produktet du er ute etter.
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ProductPage;
