const slugify = (text: string) =>
  text
    .toString() // Cast to string (optional)
    .normalize('NFKD') // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
    .toLowerCase() // Convert the string to lowercase letters
    .trim() // Remove whitespace from both sides of a string (optional)
    .replace(/\s+/g, '_') // Replace spaces with _
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '_'); // Replace multiple - with single _

export const convertToUrlSafe = (title?: string) => {
  if (!title) {
    return '';
  }

  return slugify(title);
};
