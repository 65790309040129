import React from 'react';
import styled from 'styled-components';
import { PulseLoader } from 'react-spinners';

import continueWithVipps from '../../assets/fortsett-med-vipps.png';

export const PurchaseButton = ({
  className,
  disabled,
  isLoading
}: {
  className?: string;
  disabled: boolean;
  isLoading: boolean;
}) => (
  <StyledPurchaseButton className={className} disabled={disabled} type='submit'>
    {isLoading ? (
      <PulseLoader color='white' size={16} />
    ) : (
      <PurchaseButtonImage className='purchase-button-image' src={continueWithVipps} alt='Vipps logo' />
    )}
  </StyledPurchaseButton>
);

const StyledPurchaseButton = styled.button`
  border: none;
  background: #fffdf5;
  margin-bottom: 12px;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 47px;
  width: 247px;
  border-radius: 24px;
  background: #ff5b24;

  &:hover:not(:disabled) {
    opacity: 0.85 !important;
    cursor: pointer;
  }
`;

const PurchaseButtonImage = styled.img`
  width: 220px;
`;
